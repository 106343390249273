import React from "react";
import LegalContent from "./LegalContent";
import Header from "./Header";


function Index({location}) {
  return (
    <div>
      <Header />
      <LegalContent />
    </div>
  );
}

export default Index;
