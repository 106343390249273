import React from "react";

function Header() {
  return (
    <div className="header-gradient ">
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="grid grid-cols-1">
          <div>
            <div className="mb-2">
              <span className="font-medium text-xs text-white"> LEGAL</span>
            </div>
            <h1 className=" font-medium text-white text-5xl mb-4">
              Vonage legal documentation
            </h1>
            <p className="text-xl font-normal text-white mb-16">
              Policies, terms of use, and more.
            </p>
            <ul className="list-disc pl-8 text-white">
              <li className="mb-2  text-white underline">
                Communications APIs
              </li>
              <li className="mb-2  text-white underline">VBC Global</li>
              <li className="mb-2  text-white underline">VBC US</li>
              <li className="mb-2  text-white underline">VBC UK</li>
              <li className="mb-2  text-white underline">VBC AUS</li>
              <li className="mb-2  text-white underline">VBE</li>
              <li className="mb-2  text-white underline">General</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
