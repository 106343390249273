import React from "react";

function Findapatner() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:pt-10 pt-6 md:pb-20 pb-10 px-8 ">
        legal content
       </div>
    </div>
  );
}

export default Findapatner;
